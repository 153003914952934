<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">企业现场5S检查</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        5S检查是企业维持和提升现场管理水平，使现场管理从5S的规范化、标准化到精益化转变的最有效的现场管理方法。景翔公司拥有一批在企业进行5S管理实施的培训服务和实操支持的顾问团队，多年的项目实践经验，将企业各种现场管理的多样要素和疑难杂症，转化成检查题库。景翔公司的5S检查，可以有效覆盖现场5S所需的所有管理标准和要素，并采取随机抽取的方式，以评分或扣分的方式，客观、有依据、有证据（采取拍照上传）地评价被检查区域的管理水平，并发起不符合项的改善跟踪提醒、改善证据上传等，实现无纸化，系统平台连接手机终端的智能操作。
      </p>
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/43.png"
        class="SonImgW"
        style="width: 600px"
      />
      <br />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/44.png"
        class="SonImgW"
        style="width: 600px"
      />
      <br />
      <br />
      <br />
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/45.png"
        class="SonImgW"
        style="width: 600px"
      />
      <br />
      <br />
      <br />
      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <div class="PList" style="margin-top: 10px">
        <p>1）高效实现企业高频次的5S现场检查；</p>
        <p>
          2）实现从检查发起、实施，到检查记录填写，再到整改的证据上传到验证闭环的一体化系统；
        </p>
        <p>3）实现无纸化，系统平台连接手机终端的智能操作。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>